import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { PATH_ADMIN } from './paths';
import permissionKeys from './permissionKeys';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_ADMIN.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: `${PATH_ADMIN.generalSection.dashboard}`,
      component: lazy(() => import('src/views/dashboard')),
    },
    {
      key: permissionKeys.general.alumniStory,
      exact: true,
      path: `${PATH_ADMIN.generalSection.alumniStory.list}`,
      component: lazy(() => import('src/views/alumni-story/ListView')),
    },
    {
      key: permissionKeys.general.alumniStory,
      exact: true,
      path: `${PATH_ADMIN.generalSection.alumniStory.create}`,
      component: lazy(() => import('src/views/alumni-story/CreateView')),
    },
    {
      key: permissionKeys.general.walkInEnquiry,
      exact: true,
      path: `${PATH_ADMIN.generalSection.walkInEnquiry.root}`,
      component: lazy(() => import('src/views/general/WalkInEnquiry')),
    },
    {
      key: permissionKeys.general.walkInEnquiry,
      exact: true,
      path: PATH_ADMIN.generalSection.walkInEnquiry.create,
      component: lazy(() =>
        import('src/views/general/WalkInEnquiry/CreateWalkInEnquiry'),
      ),
    },
    {
      key: permissionKeys.general.walkInEnquiry,
      exact: true,
      path: PATH_ADMIN.generalSection.walkInEnquiry.edit,
      component: lazy(() =>
        import('src/views/general/WalkInEnquiry/EditWalkInEnquiry'),
      ),
    },
    {
      key: permissionKeys.general.onlineEnquiry,
      exact: true,
      path: PATH_ADMIN.generalSection.onlineEnquiry.root,
      component: lazy(() => import('src/views/general/OnlineEnquiry')),
    },
    {
      key: permissionKeys.general.registrationEnquiry,
      exact: true,
      path: PATH_ADMIN.generalSection.registrationEnquiry.root,
      component: lazy(() => import('src/views/general/RegistrationEnquiry')),
    },
    {
      key: permissionKeys.general.corporateEnquiry,
      exact: true,
      path: PATH_ADMIN.generalSection.corporateEnquiry.root,
      component: lazy(() => import('src/views/general/CorporateEnquiry')),
    },

    // PROGRAM
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.program.root,
      exact: true,
      path: PATH_ADMIN.programSection.program.list,
      component: lazy(() => import('src/views/program/List')),
    },
    {
      key: permissionKeys.program.syllabus,
      exact: true,
      path: PATH_ADMIN.programSection.program.syllabus,
      component: lazy(() => import('src/views/program/Syllabus')),
    },

    // VIDEO LEARNING
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.videoLearning.studentQuestion,
      exact: true,
      path: PATH_ADMIN.programSection.videoLearning.studentQuestion,
      component: lazy(() =>
        import('src/views/program/VideoLearningView/StudentQuestion'),
      ),
    },
    {
      key: permissionKeys.videoLearning.access,
      exact: true,
      path: PATH_ADMIN.programSection.videoLearning.access,
      component: lazy(() =>
        import('src/views/program/VideoLearningView/VideoLearningAccess'),
      ),
    },
    {
      key: permissionKeys.videoLearning.root,
      exact: true,
      path: PATH_ADMIN.programSection.videoLearning.create,
      component: lazy(() => import('src/views/program/VideoLearningView/Form')),
    },
    {
      key: permissionKeys.videoLearning.root,
      exact: true,
      path: PATH_ADMIN.programSection.videoLearning.list.root,
      component: lazy(() =>
        import('src/views/program/VideoLearningView/VideoLearningList'),
      ),
    },
    {
      key: permissionKeys.videoLearning.root,
      exact: true,
      path: PATH_ADMIN.programSection.videoLearning.list.modules,
      component: lazy(() =>
        import(
          'src/views/program/VideoLearningView/VideoLearningList/ModuleList'
        ),
      ),
    },
    {
      key: permissionKeys.videoLearning.root,
      exact: true,
      path: PATH_ADMIN.programSection.videoLearning.list.sessions,
      component: lazy(() =>
        import(
          'src/views/program/VideoLearningView/VideoLearningList/SessionList'
        ),
      ),
    },
    {
      key: permissionKeys.videoLearning.root,
      exact: true,
      path: PATH_ADMIN.programSection.videoLearning.list.topics,
      component: lazy(() =>
        import(
          'src/views/program/VideoLearningView/VideoLearningList/TopicList'
        ),
      ),
    },
    {
      key: permissionKeys.videoLearning.root,
      path: PATH_ADMIN.programSection.videoLearning.details,
      component: lazy(() => import('src/views/program/VideoLearningView/Form')),
    },
    {
      key: permissionKeys.livestream.root,
      exact: true,
      path: PATH_ADMIN.programSection.livestream.list,
      component: lazy(() => import('src/views/program/LivestreamView/List')),
    },
    {
      key: permissionKeys.livestream.root,
      exact: true,
      path: PATH_ADMIN.programSection.livestream.module,
      component: lazy(() => import('src/views/program/LivestreamView/Modules')),
    },
    {
      key: permissionKeys.livestream.root,
      exact: true,
      path: PATH_ADMIN.programSection.livestream.session,
      component: lazy(() => import('src/views/program/LivestreamView/Session')),
    },
    {
      key: permissionKeys.livestream.root,
      exact: true,
      path: PATH_ADMIN.programSection.livestream.material,
      component: lazy(() =>
        import('src/views/program/LivestreamView/Material'),
      ),
    },
    {
      key: permissionKeys.livestream.schedule,
      exact: true,
      path: PATH_ADMIN.programSection.livestream.schedule,
      component: lazy(() =>
        import('src/views/program/LivestreamView/Schedule'),
      ),
    },
    {
      key: permissionKeys.livestream.accesss,
      exact: true,
      path: PATH_ADMIN.programSection.livestream.access,
      component: lazy(() => import('src/views/program/LivestreamView/Access')),
    },
    {
      key: permissionKeys.videoLearning.practiceSession,
      exact: true,
      path: PATH_ADMIN.programSection.videoLearning.practiceSession.root,
      component: lazy(() =>
        import('src/views/program/VideoLearningView/PracticeSession'),
      ),
    },
    {
      key: permissionKeys.videoLearning.practiceSession,
      exact: true,
      path: PATH_ADMIN.programSection.videoLearning.practiceSession.group.root,
      component: lazy(() =>
        import(
          'src/views/program/VideoLearningView/PracticeSession/PracticeSessionGroup'
        ),
      ),
    },
    {
      key: permissionKeys.videoLearning.practiceSession,
      exact: true,
      path:
        PATH_ADMIN.programSection.videoLearning.practiceSession.group.schedule,
      component: lazy(() =>
        import(
          'src/views/program/VideoLearningView/PracticeSession/PracticeSessionGroup/PracticeSessionSchedule'
        ),
      ),
    },
    // EXAM
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.exam.root,
      exact: true,
      path: PATH_ADMIN.programSection.exam.list,
      component: lazy(() => import('src/views/exam/List')),
    },
    {
      key: permissionKeys.exam.root,
      exact: true,
      path: PATH_ADMIN.programSection.exam.create,
      component: lazy(() => import('src/views/exam/Form')),
    },
    {
      key: permissionKeys.exam.root,
      exact: true,
      path: PATH_ADMIN.programSection.exam.preview,
      component: lazy(() => import('src/views/exam/Preview')),
    },
    {
      key: permissionKeys.exam.schedulesTranscript,
      exact: true,
      path: PATH_ADMIN.programSection.exam.batchList,
      component: lazy(() => import('src/views/exam/BatchList')),
    },
    {
      key: permissionKeys.exam.schedulesTranscript,
      exact: true,
      path: PATH_ADMIN.programSection.exam.transcript,
      component: lazy(() => import('src/views/exam/Transcript')),
    },
    {
      key: permissionKeys.exam.schedulesTranscript,
      exact: true,
      path: PATH_ADMIN.programSection.exam.schedule,
      component: lazy(() => import('src/views/exam/Schedule')),
    },
    {
      key: permissionKeys.exam.schedulesTranscript,
      exact: true,
      path: PATH_ADMIN.programSection.exam.studentResults,
      component: lazy(() => import('src/views/exam/StudentResults')),
    },
    {
      key: permissionKeys.exam.schedulesTranscript,
      exact: true,
      path: PATH_ADMIN.programSection.exam.resultDetails,
      component: lazy(() => import('src/views/exam/ExamResultDetail')),
    },

    // EVALUATION
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.evaluation.root,
      exact: true,
      path: PATH_ADMIN.programSection.evaluation.batch,
      component: lazy(() => import('src/views/program/Evaluation/Batch')),
    },
    {
      key: permissionKeys.evaluation.root,
      exact: true,
      path: PATH_ADMIN.programSection.evaluation.schedule,
      component: lazy(() => import('src/views/program/Evaluation/Schedules')),
    },

    {
      key: permissionKeys.evaluation.root,
      exact: true,
      path: PATH_ADMIN.programSection.evaluation.result,
      component: lazy(() =>
        import('src/views/program/Evaluation/EvaluationResults'),
      ),
    },

    // SCHOLARSHIP
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.scholarship.root,
      exact: true,
      path: PATH_ADMIN.programSection.scholarship.root,
      component: lazy(() => import('src/views/program/Scholarship')),
    },
    {
      key: permissionKeys.scholarship.root,
      exact: true,
      path: PATH_ADMIN.programSection.scholarship.applicantList,
      component: lazy(() =>
        import('src/views/program/Scholarship/ApplicantList'),
      ),
    },
    // BSBSK
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.paylater.root,
      exact: true,
      path: PATH_ADMIN.programSection.paylater.root,
      component: lazy(() => import('src/views/program/Paylater')),
    },
    {
      key: permissionKeys.paylater.root,
      exact: true,
      path: PATH_ADMIN.programSection.paylater.applicantList,
      component: lazy(() => import('src/views/program/Paylater/ApplicantList')),
    },
    // DIGITAL SIGNATURE
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.digitalSignature.root,
      exact: true,
      path: PATH_ADMIN.programSection.digitalSignature.document.root,
      component: lazy(() =>
        import('src/views/program/DigitalSignature/Document'),
      ),
    },
    {
      key: permissionKeys.digitalSignature.root,
      exact: true,
      path: PATH_ADMIN.programSection.digitalSignature.document.attendee,
      component: lazy(() =>
        import('src/views/program/DigitalSignature/Document/Attendee'),
      ),
    },
    // ACADEMIC SCHEDULE
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.academicSchedule.root,
      exact: true,
      path: PATH_ADMIN.programSection.academicSchedule.root,
      component: lazy(() => import('src/views/program/AcademicSchedule')),
    },
    // GRADE
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.grade.root,
      exact: true,
      path: PATH_ADMIN.programSection.grade.root,
      component: lazy(() => import('src/views/program/Grade/GradeList')),
    },
    {
      key: permissionKeys.grade.root,
      exact: true,
      path: PATH_ADMIN.programSection.grade.mainCategory,
      component: lazy(() =>
        import('src/views/program/Grade/GradeMainCategory'),
      ),
    },
    {
      key: permissionKeys.grade.root,
      exact: true,
      path: PATH_ADMIN.programSection.grade.subCategory,
      component: lazy(() => import('src/views/program/Grade/GradeSubCategory')),
    },
    {
      key: permissionKeys.grade.batch,
      exact: true,
      path: PATH_ADMIN.programSection.grade.batch.root,
      component: lazy(() => import('src/views/program/Grade/Batch')),
    },
    {
      key: permissionKeys.grade.batch,
      exact: true,
      path: PATH_ADMIN.programSection.grade.batch.score,
      component: lazy(() => import('src/views/program/Grade/BatchScore')),
    },
    // YOUTUBE
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.elearningHub.youtubePlaylists,
      exact: true,
      path: PATH_ADMIN.eLearningHubSection.youtube.playlists,
      component: lazy(() =>
        import(`src/views/e-learning-hub/Youtube/Playlists`),
      ),
    },
    {
      key: permissionKeys.elearningHub.youtubeVideos,
      exact: true,
      path: PATH_ADMIN.eLearningHubSection.youtube.videos,
      component: lazy(() => import(`src/views/e-learning-hub/Youtube/Videos`)),
    },
    // COMMUNITY PARTNER
    //-----------------------------------------------------------------------
    {
      key: permissionKeys.communityPartner.root,
      exact: true,
      path: PATH_ADMIN.programSection.communityPartner.root,
      component: lazy(() => import('src/views/community/CommunityPartner')),
    },
    // MANAGEMENT
    {
      key: permissionKeys.promotion.voucherCode,
      exact: true,
      path: PATH_ADMIN.managementSection.promotions.voucherCode,
      component: lazy(() =>
        import('src/views/management/promotion/voucher-code'),
      ),
    },
    {
      key: permissionKeys.promotion.promo,
      exact: true,
      path: PATH_ADMIN.managementSection.promotions.createPromo,
      component: lazy(() =>
        import('src/views/management/promotion/promo/CreatePromo'),
      ),
    },
    {
      key: permissionKeys.promotion.promo,
      exact: true,
      path: PATH_ADMIN.managementSection.promotions.editPromo,
      component: lazy(() =>
        import('src/views/management/promotion/promo/EditPromo'),
      ),
    },
    {
      key: permissionKeys.promotion.promo,
      exact: true,
      path: PATH_ADMIN.managementSection.promotions.promo,
      component: lazy(() => import('src/views/management/promotion/promo')),
    },

    // Finance
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.finance.eFaktur,
      exact: true,
      path: PATH_ADMIN.managementSection.finance.eFaktur,
      component: lazy(() => import('src/views/management/FinanceView/EFaktur')),
    },
    {
      key: permissionKeys.finance.purchaseOrder,
      exact: true,
      path: PATH_ADMIN.managementSection.finance.purchaseOrder,
      component: lazy(() =>
        import('src/views/management/FinanceView/PurchaseOrder'),
      ),
    },
    {
      key: permissionKeys.finance.transactionInstallment,
      exact: true,
      path: PATH_ADMIN.managementSection.finance.transactionInstallment,
      component: lazy(() =>
        import('src/views/management/FinanceView/TransactionInstallment'),
      ),
    },

    // USER
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.user.root,
      exact: true,
      path: PATH_ADMIN.userSection.user.list,
      component: lazy(() => import('src/views/user/UserView/List')),
    },
    {
      key: permissionKeys.user.role,
      exact: true,
      path: PATH_ADMIN.userSection.user.role,
      component: lazy(() => import('src/views/user/UserView/Role')),
    },
    {
      key: permissionKeys.student.support,
      exact: true,
      path: PATH_ADMIN.userSection.student.studentSupport,
      component: lazy(() =>
        import('src/views/user/StudentView/StudentSupport'),
      ),
    },
    {
      key: permissionKeys.student.report,
      exact: true,
      path: PATH_ADMIN.userSection.student.studentReport,
      component: lazy(() => import('src/views/user/StudentView/StudentReport')),
    },
    {
      key: permissionKeys.student.announcement,
      exact: true,
      path: PATH_ADMIN.userSection.student.studentAnnouncement,
      component: lazy(() =>
        import('src/views/user/StudentView/StudentAnnouncement'),
      ),
    },
    {
      key: permissionKeys.student.attendees,
      exact: true,
      path: PATH_ADMIN.userSection.student.attendees,
      component: lazy(() => import('src/views/user/StudentView/Attendees')),
    },
    {
      key: permissionKeys.student.birthday,
      exact: true,
      path: PATH_ADMIN.userSection.student.studentBirthday,
      component: lazy(() =>
        import('src/views/user/StudentView/StudentBirthday'),
      ),
    },
    {
      key: permissionKeys.student.root,
      exact: true,
      path: PATH_ADMIN.userSection.student.studentList,
      component: lazy(() => import('src/views/user/StudentView/StudentList')),
    },
    {
      key: permissionKeys.student.recommendation,
      exact: true,
      path: PATH_ADMIN.userSection.student.studentRecommendation,
      component: lazy(() =>
        import('src/views/user/StudentView/StudentRecommendation'),
      ),
    },
    {
      key: permissionKeys.lecturer.root,
      exact: true,
      path: PATH_ADMIN.userSection.lecturer.list,
      component: lazy(() => import('src/views/user/LecturerView/List')),
    },
    {
      key: permissionKeys.operational.root,
      exact: true,
      path: PATH_ADMIN.userSection.branchOperator.list,
      component: lazy(() => import('src/views/user/BranchOperator/List')),
    },

    // COMMUNITY
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.community.pacMember,
      exact: true,
      path: PATH_ADMIN.communitySection.pacMember.root,
      component: lazy(() => import('src/views/community/pac-member')),
    },
    {
      key: permissionKeys.community.tribe,
      exact: true,
      path: PATH_ADMIN.communitySection.tribe.root,
      component: lazy(() => import('src/views/community/tribes')),
    },
    {
      key: permissionKeys.community.tribe,
      exact: true,
      path: PATH_ADMIN.communitySection.tribe.create,
      component: lazy(() => import('src/views/community/tribes/CreateTribe')),
    },
    {
      key: permissionKeys.community.tribe,
      exact: true,
      path: PATH_ADMIN.communitySection.tribe.edit,
      component: lazy(() => import('src/views/community/tribes/EditTribe')),
    },
    {
      key: permissionKeys.community.activity,
      exact: true,
      path: PATH_ADMIN.communitySection.activity.root,
      component: lazy(() => import('src/views/community/activity')),
    },
    {
      key: permissionKeys.community.activity,
      exact: true,
      path: PATH_ADMIN.communitySection.activity.create,
      component: lazy(() =>
        import('src/views/community/activity/CreateActivity'),
      ),
    },
    {
      key: permissionKeys.community.activity,
      exact: true,
      path: PATH_ADMIN.communitySection.activity.edit,
      component: lazy(() =>
        import('src/views/community/activity/EditActivity'),
      ),
    },
    {
      key: permissionKeys.community.jobPosting,
      exact: true,
      path: PATH_ADMIN.communitySection.jobPosting.root,
      component: lazy(() => import('src/views/community/job-posting')),
    },
    {
      key: permissionKeys.community.jobPosting,
      exact: true,
      path: PATH_ADMIN.communitySection.jobPosting.create,
      component: lazy(() =>
        import('src/views/community/job-posting/CreateJobPosting'),
      ),
    },
    {
      key: permissionKeys.community.jobPosting,
      exact: true,
      path: PATH_ADMIN.communitySection.jobPosting.edit,
      component: lazy(() =>
        import('src/views/community/job-posting/EditJobPosting'),
      ),
    },
    {
      key: permissionKeys.community.jobPosting,
      exact: true,
      path: PATH_ADMIN.communitySection.jobPosting.applicant.list,
      component: lazy(() =>
        import('src/views/community/job-posting/ApplicantsJobPosting'),
      ),
    },
    {
      key: permissionKeys.community.jobPosting,
      exact: true,
      path: PATH_ADMIN.communitySection.jobPosting.applicant.detail,
      component: lazy(() =>
        import('src/views/community/job-posting/ApplicantDetailJobPosting'),
      ),
    },
    {
      key: permissionKeys.community.alumni,
      exact: true,
      path: PATH_ADMIN.communitySection.alumni.root,
      component: lazy(() => import('src/views/community/Alumni')),
    },
    {
      key: permissionKeys.community.alumni,
      exact: true,
      path: PATH_ADMIN.communitySection.alumni.detail,
      component: lazy(() => import('src/views/community/Alumni/DetailAlumni')),
    },
    {
      key: permissionKeys.community.company,
      exact: true,
      path: PATH_ADMIN.communitySection.company.root,
      component: lazy(() => import('src/views/community/company')),
    },
    {
      key: permissionKeys.community.alumniContact,
      exact: true,
      path: PATH_ADMIN.communitySection.alumniContact.list,
      component: lazy(() =>
        import('src/views/community/AlumniContactView/List'),
      ),
    },
    {
      key: permissionKeys.community.alumniContact,
      exact: true,
      path: PATH_ADMIN.communitySection.alumniContact.enquiry,
      component: lazy(() =>
        import('src/views/community/AlumniContactView/Enquiry'),
      ),
    },
    {
      key: permissionKeys.community.alumniContact,
      exact: true,
      path: PATH_ADMIN.communitySection.alumniContact.create,
      component: lazy(() =>
        import(
          'src/views/community/AlumniContactView/List/CreateAlumniContact'
        ),
      ),
    },
    {
      key: permissionKeys.community.alumniContact,
      exact: true,
      path: PATH_ADMIN.communitySection.alumniContact.edit,
      component: lazy(() =>
        import('src/views/community/AlumniContactView/List/EditAlumniContact'),
      ),
    },

    // REPORTS
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.report.salesReport,
      exact: true,
      path: PATH_ADMIN.reportSection.salesReport,
      component: lazy(() => import('src/views/report/SalesReport')),
    },
    {
      key: permissionKeys.report.programSalesReport,
      exact: true,
      path: PATH_ADMIN.reportSection.programSalesReport,
      component: lazy(() => import('src/views/report/ProgramSalesReport')),
    },
    {
      key: permissionKeys.report.installmentReport,
      exact: true,
      path: PATH_ADMIN.reportSection.installmentReport,
      component: lazy(() =>
        import('src/views/report/TransactionInstallmentReport'),
      ),
    },
    {
      key: permissionKeys.report.installmentARReport,
      exact: true,
      path: PATH_ADMIN.reportSection.installmentARReport,
      component: lazy(() =>
        import('src/views/report/TransactionInstallmentARReport'),
      ),
    },
    {
      key: permissionKeys.report.newInstallmentReport,
      exact: true,
      path: PATH_ADMIN.reportSection.newInstallmentReport,
      component: lazy(() =>
        import('src/views/report/NewTransactionInstallmentReport'),
      ),
    },
    {
      key: permissionKeys.report.suspendedInstallmentReport,
      exact: true,
      path: PATH_ADMIN.reportSection.suspendedInstallmentReport,
      component: lazy(() =>
        import('src/views/report/SuspendedInstallmentReport'),
      ),
    },

    // SUPPORT
    // ----------------------------------------------------------------------

    {
      key: permissionKeys.support.root,
      exact: true,
      path: PATH_ADMIN.SupportSection.supportTicket.list,
      component: lazy(() => import('src/views/support/supportTicket/List')),
    },
    {
      key: permissionKeys.support.root,
      exact: true,
      path: PATH_ADMIN.SupportSection.supportTicket.detail,
      component: lazy(() =>
        import(
          'src/views/support/supportTicket/List/components/SupportTicketDetail'
        ),
      ),
    },

    // NOTIFICATION
    // ----------------------------------------------------------------------
    {
      key: permissionKeys.notification.root,
      exact: true,
      path: PATH_ADMIN.notificationSection.root,
      component: lazy(() => import('src/views/notification')),
    },
    {
      key: permissionKeys.notification.root,
      exact: true,
      path: PATH_ADMIN.notificationSection.create,
      component: lazy(() =>
        import('src/views/notification/createNotification'),
      ),
    },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/not-found" />,
    },
  ],
};

export default AppRoutes;
